import React, { useEffect } from "react";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { AiFillMail, AiOutlinePhone, AiFillClockCircle } from "react-icons/ai";
import { navigate } from "gatsby";
import { useApp } from "../context/AppProvider";

import "./styles/header.scss";

const Header = () => {
  const { dispatch, state } = useApp();

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function HeaderScroll() {
      const currentScrollPos = window.pageYOffset;

      if (document.getElementById("header")) {
        if (prevScrollpos > currentScrollPos || currentScrollPos < 90) {
          document.getElementById("header").style.top = "0";
        } else {
          document.getElementById("header").style.top = "-154px";
        }
      }

      prevScrollpos = currentScrollPos;
    };
  }, []);

  return (
    <Box
      id="header"
      className="header"
      position="fixed"
      top={0}
      width="100%"
      as="header"
      zIndex="99"
      display="flex"
      flexDir="column"
      transition="top 0.4s ease-in-out"
      boxShadow="rgba(0, 0, 0, 0.14) 0px 2px 8px"
    >
      <Box
        px={{ base: "16px", md: "2rem", lg: "8rem" }}
        py="16px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg="#FFFFFF"
      >
        <Box
          display="flex"
          alignItems="center"
          cursor="pointer"
          onClick={() => navigate("/")}
        >
          <Text fontSize={{ base: "24px", md: "32px" }} fontWeight="bold">
            seniors
          </Text>
          <Text
            color="#094B46"
            fontSize={{ base: "24px", md: "32px" }}
            fontWeight="bold"
          >
            concierge
          </Text>
        </Box>
        <Box
          onClick={() =>
            dispatch({
              type: "toggleDrawer",
              isDrawerOpen: !state?.isDrawerOpen,
            })
          }
          id="nav-icon"
          className={state?.isDrawerOpen ? "open" : null}
          display={{ base: "initial", md: "none" }}
        >
          <span />
          <span />
          <span />
        </Box>
        <Flex
          display={{ base: "none", md: "flex" }}
          flexDir="column"
          alignItems="flex-end"
        >
          <Text fontSize={{ base: "14px", md: "16px" }} fontWeight="semibold">
            Speak with A Medicare Specialist Today!
          </Text>
          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontWeight="bold"
            fontSize="28px"
            color="primary.500"
          >
            <Icon mr="8px" as={AiOutlinePhone} /> 1-888-408-6913
          </Text>
        </Flex>
      </Box>
      <Box
        backgroundImage="linear-gradient(to right, #094B46, #011A24)"
        px={{ base: "16px", md: "2rem", lg: "8rem" }}
        py="16px"
        display="flex"
        justifyContent={{ base: "center", sm: "space-between" }}
      >
        <Box display="flex">
          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            color="#ffffff"
            mr={{ base: 0, sm: 4, lg: 6 }}
            fontSize={{ base: "14px", lg: "16px" }}
          >
            <Icon mr="8px" as={AiFillClockCircle} /> Available Daily From 8:00
            AM to 6:00 PM
          </Text>
          <Text
            display={{ base: "none", md: "flex" }}
            cursor="pointer"
            alignItems="center"
            color="#ffffff"
            fontSize={{ base: "14px", lg: "16px" }}
          >
            <Icon mr="8px" as={AiFillMail} />{" "}
            customer.service@seniorsconcierge.net
          </Text>
        </Box>
        <Box display={{ base: "none", sm: "flex" }}>
          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "14px", lg: "16px" }}
            color="#ffffff"
            fontWeight="bold"
            ml="8"
            onClick={() => navigate("/")}
          >
            HOME
          </Text>
          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "14px", lg: "16px" }}
            color="#ffffff"
            fontWeight="bold"
            ml="8"
            onClick={() => navigate("/about-us")}
          >
            ABOUT US
          </Text>
          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "14px", lg: "16px" }}
            color="#ffffff"
            fontWeight="bold"
            ml="8"
            onClick={() => navigate("/contact-us")}
          >
            CONTACT US
          </Text>
          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "14px", lg: "16px" }}
            color="#ffffff"
            fontWeight="bold"
            ml="8"
            onClick={() => navigate("/find-plan")}
          >
            FIND PLAN
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
