import React from "react";
import { Box, Flex, Icon, Text, Center } from "@chakra-ui/react";
import { AiOutlinePhone } from "react-icons/ai";
import { navigate } from "gatsby";

const Footer = () => {
  return (
    <Box id="footer" as="footer" mb="4rem">
      <Box
        px={{ base: "16px", md: "2rem", lg: "4rem" }}
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        backgroundColor="#FFFFFF"
        mb="8"
        flexDir={{ base: "column", md: "row" }}
      >
        <Box>
          <Box
            display="flex"
            alignItems="center"
            cursor="pointer"
            onClick={() => navigate("/")}
            mb="2"
          >
            <Text fontSize={{ base: "24px", md: "32px" }} fontWeight="bold">
              seniors
            </Text>
            <Text
              color="#094B46"
              fontSize={{ base: "24px", md: "32px" }}
              fontWeight="bold"
            >
              concierge
            </Text>
          </Box>
          <Box display="flex" flexDir={{ base: "column", md: "row" }}>
            <Text
              cursor="pointer"
              display="flex"
              alignItems="center"
              fontSize="md"
              color="#333333"
              fontWeight="bold"
              onClick={() => navigate("/")}
              mb={{ base: 2, md: 0 }}
            >
              HOME
            </Text>
            <Text
              cursor="pointer"
              display="flex"
              alignItems="center"
              fontSize="md"
              color="#333333"
              fontWeight="bold"
              ml={{ base: 0, md: 8 }}
              onClick={() => navigate("/about-us")}
              mb={{ base: 2, md: 0 }}
            >
              ABOUT US
            </Text>
            <Text
              cursor="pointer"
              display="flex"
              alignItems="center"
              fontSize="md"
              color="#333333"
              fontWeight="bold"
              ml={{ base: 0, md: 8 }}
              onClick={() => navigate("/contact-us")}
              mb={{ base: 2, md: 0 }}
            >
              CONTACT US
            </Text>
            <Text
              cursor="pointer"
              display="flex"
              alignItems="center"
              fontSize="md"
              color="#333333"
              fontWeight="bold"
              ml={{ base: 0, md: 8 }}
              onClick={() => navigate("/find-plan")}
              mb={{ base: 2, md: 0 }}
            >
              FIND PLAN
            </Text>
          </Box>
        </Box>

        <Flex
          mt={{ base: 6, md: 0 }}
          flexDir="column"
          alignItems={{ base: "flex-start", md: "flex-end" }}
        >
          <Text fontSize="16px" fontWeight="semibold">
            Speak with A Medicare Specialist Today!
          </Text>
          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontWeight="bold"
            fontSize="28px"
            color="primary.500"
          >
            <Icon mr="8px" as={AiOutlinePhone} /> 1-888-408-6913
          </Text>
        </Flex>
      </Box>
      <Box
        px={{ base: "16px", md: "2rem", lg: "4rem" }}
        py="16px"
        mb={{ base: "2", lg: "8" }}
      >
        <Box
          display="grid"
          gridTemplateColumns={{ base: "none", md: "1fr 1fr 1fr" }}
          gridTemplateRows={{ base: "auto auto auto", md: "none" }}
          gridGap={{ base: "16px", lg: "40px" }}
        >
          <Box display="flex" flexDir="column">
            <Text fontSize="14px" fontWeight="semibold" mb="4px">
              Privacy and Security
            </Text>
            <Text fontSize="14px" fontWeight="300">
              Privacy and Security Your privacy and security are extremely
              important to us. Your personal information is protected by our
              Privacy Policy. Our website is backed by certified internet
              security standards.
            </Text>
          </Box>
          <Box display="flex" flexDir="column">
            <Text fontSize="14px" fontWeight="semibold" mb="4px">
              Medicare Costs
            </Text>
            <Text fontSize="14px" fontWeight="300">
              The costs of Medicare plans are strongly regulated by the federal
              government.​ Any individual plan listed on our site carries the
              same costs and offers the exact same benefits regardless of
              whether you purchase it from our site, a government website, or
              your local insurance broker. ​Our commissions are paid by
              insurance carriers, so there is no additional cost to you, our
              consumer.
            </Text>
          </Box>
          <Box display="flex" flexDir="column">
            <Text fontSize="14px" fontWeight="semibold" mb="4px">
              Learn More
            </Text>
            <Text fontSize="14px" fontWeight="300">
              To learn about Medicare plans you may be eligible for, you can
              contact a licensed insurance agency such as Morgan Fidelity
              Associates. Call Medicare Enrollment Center licensed sales agents
              on 1-888-408-6913 We are available Monday through Friday, 9AM to
              8PM ET.
            </Text>
          </Box>
        </Box>
      </Box>

      <Box>
        <Center
          display="flex"
          flexDir="column"
          className="wrapper-md"
          textAlign="center"
        >
          <Text fontSize="14px" fontWeight="300" mb="4">
            This is a solicitation of insurance. An agent/producer may contact
            you. Seniors Concierge is a DBA of [company here]. Seniors Concierge
            is not connected with or endorsed by the United States government or
            the federal Medicare program.
          </Text>
          <Text fontSize="16px" fontWeight="300">
            Copyright © Seniors Concierge All rights reserved.
          </Text>
        </Center>
      </Box>
    </Box>
  );
};

export default Footer;
