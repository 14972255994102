import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Flex,
  Icon,
  Grid,
  Link,
  Text,
  Box,
} from "@chakra-ui/react";
import { useApp } from "../context/AppProvider";
import { navigate } from "gatsby";
import { ImFacebook, ImTwitter, ImInstagram } from "react-icons/im";
import {
  AiFillEnvironment,
  AiFillMail,
  AiFillHeart,
  AiFillPhone,
} from "react-icons/ai";

const MobileDrawer = () => {
  const { state, dispatch } = useApp();

  const onCloseDrawer = () => {
    dispatch({ type: "toggleDrawer", isDrawerOpen: false });
  };

  const onNavigate = (url) => {
    navigate(url);
    onCloseDrawer();
  };

  return (
    <Drawer
      trapFocus={false}
      size="full"
      isOpen={state?.isDrawerOpen}
      placement="right"
      onClose={onCloseDrawer}
    >
      <DrawerOverlay>
        <DrawerContent h="100%" bg="#ffffff">
          <DrawerBody
            display="flex"
            flexDirection="column"
            pt="113px"
            pb={8}
            color="#333333"
          >
            <Grid
              pt={4}
              templateRows="repeat(5, auto)"
              rowGap={6}
              justifyContent="flex-start"
            >
              <Link
                onClick={() => onNavigate("/")}
                color="#333333"
                fontSize="24px"
                lineHeight="40px"
              >
                HOME
              </Link>
              <Link
                onClick={() => onNavigate("/about-us")}
                fontSize="24px"
                lineHeight="40px"
              >
                ABOUT US
              </Link>
              <Link
                onClick={() => onNavigate("/contact-us")}
                fontSize="24px"
                lineHeight="40px"
              >
                CONTACT US
              </Link>
              <Link
                onClick={() => onNavigate("/find-plan")}
                fontSize="24px"
                lineHeight="40px"
              >
                FIND PLAN
              </Link>
            </Grid>
            <Box mt="auto">
              <Grid
                templateColumns="repeat(3, 1fr)"
                columnGap="40px"
                gridTemplateColumns={{ base: "none", md: "repeat(3, 1fr)" }}
                gridTemplateRows={{ base: "auto auto auto", md: "none" }}
                gridRowGap={{ base: "16px", md: 0 }}
              >
                <Box
                  borderBottom={{ base: "1px solid lightgray", md: "none" }}
                  borderLeft={{ base: "none", md: "1px solid lightgray" }}
                  pl={{ base: 0, md: "16px" }}
                  pb={{ base: 4, md: 0 }}
                >
                  <Text display="flex" alignItems="center">
                    <Icon mr="8px" as={AiFillEnvironment} /> Location
                  </Text>
                  <Text
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    fontSize="md"
                    color="#333333"
                  >
                    2021 Prospect Rd, Tamarac, FL 33309
                  </Text>
                </Box>

                <Box
                  borderBottom={{ base: "1px solid lightgray", md: "none" }}
                  borderLeft={{ base: "none", md: "1px solid lightgray" }}
                  pl={{ base: 0, md: "16px" }}
                  pb={{ base: 4, md: 0 }}
                >
                  <Text display="flex" alignItems="center">
                    <Icon mr="8px" as={AiFillMail} /> Email
                  </Text>
                  <Text
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    fontSize="md"
                    color="#333333"
                  >
                    customer.service@seniorsconcierge.net
                  </Text>
                </Box>
                <Box
                  borderBottom={{ base: "1px solid lightgray", md: "none" }}
                  borderLeft={{ base: "none", md: "1px solid lightgray" }}
                  pl={{ base: 0, md: "16px" }}
                  pb={{ base: 4, md: 0 }}
                >
                  <Text display="flex" alignItems="center">
                    <Icon mr="8px" as={AiFillPhone} /> Call Us
                  </Text>
                  <Text
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    fontSize="md"
                    color="#333333"
                  >
                    1-888-408-6913
                  </Text>
                </Box>
                <Box
                  borderBottom={{ base: "1px solid lightgray", md: "none" }}
                  borderLeft={{ base: "none", md: "1px solid lightgray" }}
                  pl={{ base: 0, md: "16px" }}
                  pb={{ base: 4, md: 0 }}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text display="flex" alignItems="center">
                    <Icon mr="8px" as={AiFillHeart} /> Follow Us
                  </Text>
                  <Flex alignItems="center">
                    <Icon
                      mr={6}
                      color="#333333"
                      fontSize="20px"
                      as={ImFacebook}
                      cursor="pointer"
                    />
                    <Icon
                      mr={6}
                      color="#333333"
                      fontSize="20px"
                      as={ImInstagram}
                      cursor="pointer"
                    />
                    <Icon
                      color="#333333"
                      fontSize="20px"
                      as={ImTwitter}
                      cursor="pointer"
                    />
                  </Flex>
                </Box>
              </Grid>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default MobileDrawer;
